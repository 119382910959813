import { graphql } from "gatsby"
import Container from "../../components/container"
import Header from "../../components/header"
import MoreStories from "../../components/moreStories"
import PostBody from "../../components/postBody"
import PostHeader from "../../components/postHeader"
import SectionSeparator from "../../components/sectionSeparator"
import { HelmetDatoCms } from "gatsby-source-datocms"

export default function Post({ data: { site, post, morePosts } }) {
  return (
    <Container>
      <HelmetDatoCms seo={post.seo} favicon={site.favicon} />
      <Header />
      <article>
        <PostHeader
          title={post.title}
          coverImage={post.coverImage}
          date={post.date}
          author={post.author}
          slug={post.slug}
        />
        <PostBody content={post.content} />
      </article>
      <SectionSeparator />
      {morePosts.nodes.length > 0 && <MoreStories posts={morePosts.nodes} />}
    </Container>
  )
}

export const query = graphql`
  query PostBySlug($id: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    post: datoCmsPost(id: { eq: $id }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      date
      coverImage {
        gatsbyImageData(width: 470)
      }
      content {
        value
      }
      author {
        name
        picture {
          gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
        }
      }
    }
    morePosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 2, filter: { id: { ne: $id } }) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          small: gatsbyImageData(width: 760)
        }
        author {
          name
          picture {
            gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
          }
        }
      }
    }
  }
`
