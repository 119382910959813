import Avatar from "./avatar"
import Date from "./date"
import CoverImage from "../components/coverImage"
import PostTitle from "./postTitle"

export default function PostHeader({ title, coverImage, date, author, slug }) {
  return (
    <>
      <PostTitle>{title}</PostTitle>

      <div className="mb-8 md:mb-16 -mx-5 sm:mx-0">
        <CoverImage title={title} fluid={coverImage?.large} slug={slug} />
      </div>
      <div className="text-sm text-right">
        <div className="mb-6 text-md text-left">
          <Date dateString={date} />
        </div>
        <div className="block md:hidden mb-6">
          <Avatar name={author?.name} picture={author?.picture} />
        </div>
        <div className="hidden md:block md:mb-12">
          <Avatar name={author?.name} picture={author?.picture} />
        </div>
      </div>
    </>
  )
}
